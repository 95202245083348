import type { QueryResult } from '@apollo/client';
import React, { useId } from 'react';
import type { CSSProperties } from 'react';
import { SharedComponent } from '../../../enums';
import { LoadingSize } from '../../../types/enums';
import useTranslation from '../../useTranslation';
import ApolloErrorAlert from '../ApolloErrorAlert/ApolloErrorAlert';
import { LoadingVariant } from '../Loading/enums';
import Loading from '../Loading/Loading';
import type { LoadingVariantTypeAndProps } from '../Loading/types';
import SystemAlert from '../SystemAlert/SystemAlert';
import { useClassNameMapper } from 'react-bootstrap';

interface Props<TData, TVariables> {
  /**
   * The query result from a GraphQL call.
   */
  queryResult: QueryResult<TData, TVariables>;
  /**
   * Whether valid data is required to be returned from the query before rendering.
   */
  required?: boolean;
  /**
   * A message to display in case the query returns no data.
   */
  requiredMessage?: string;
  /**
   * A callback function called only when the data is available from the query.
   *
   * @callback
   */
  children({
    isLoading,
    Loading
  }: {
    isLoading: boolean;
    Loading: () => React.ReactNode;
  }): React.ReactNode;
  /**
   * The size of the loading indicator.
   */
  loadingSize?: LoadingSize;
  /**
   * Force the alert to be shown, even in production
   */
  forceShowErrors?: boolean;
  /**
   * ClassName on the loading wrapper.
   */
  loadingWrapClassName?: string;
  /**
   * Loading styles to apply when loading content.
   */
  loadingWrapStyles?: CSSProperties;
  /**
   * Whether to use the full height of the available space for the loading indicator.
   */
  useFullLoadingHeight?: boolean;
  /**
   * Whether to show the children while loading.
   */
  showChildrenWhileLoading?: boolean;
}

/**
 * Adds support for handling the loading state, error state, and display of a
 * child component once data has successfully loaded from a GraphQL query.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const QueryHandler = <TData, TVariables>({
  queryResult,
  children = (): null => null,
  required = false,
  requiredMessage,
  loadingSize = LoadingSize.LG,
  forceShowErrors = false,
  loadingWrapClassName,
  loadingWrapStyles,
  useFullLoadingHeight = true,
  showChildrenWhileLoading = false
}: Props<TData, TVariables>): React.ReactElement => {
  const uid = useId();
  const cx = useClassNameMapper();
  const { loading, error, data } = queryResult;
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.QUERY_HANDLER);
  const loadingVariant: LoadingVariantTypeAndProps = {
    type: LoadingVariant.DOT,
    props: {
      size: loadingSize,
      useFullHeight: useFullLoadingHeight
    }
  };
  const hasLoadingWrap = loadingWrapClassName || loadingWrapStyles;

  if (textLoading) {
    return null;
  }

  const Loader = () => {
    if (hasLoadingWrap) {
      return (
        <div style={loadingWrapStyles} className={cx(loadingWrapClassName)}>
          <Loading variant={loadingVariant} />
        </div>
      );
    }

    return <Loading variant={loadingVariant} />;
  };

  const isLoading = !data && loading;

  if (isLoading && !showChildrenWhileLoading) {
    return <Loader />;
  }

  if (error != null) {
    return <ApolloErrorAlert error={error} forceShow={forceShowErrors} />;
  }

  if (!data && !showChildrenWhileLoading) {
    // This can be null if the component is dynamically loaded using `dynamic/next`.
    return null;
  }

  if (required && Object.values(data).filter(i => i !== null).length === 0) {
    return <SystemAlert id={uid} title={formatMessage('title')} message={requiredMessage} />;
  }

  return <>{children({ isLoading: isLoading, Loading: Loader })}</>;
};

export default QueryHandler;
